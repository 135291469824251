<div #overview class="example-container">
  <div class="row tab-row" *ngIf="hasMoreHeader">
    <div class="col-12 grid-tab" grid-tab-def>
      <ng-content select="[grid-tab-buttons]"></ng-content>
    </div>
  </div>

  <div class="rounded resp-grid grid-div" [id]="isGridTab == false ? 'main-grid-no-tabs' : 'main-grid'"
    [ngClass]="[customStleClasses ? customStleClasses : '']">
    <div>
      <table class="table-hover w-100" cdkDropList cdkDropListOrientation="horizontal" mat-table
        [dataSource]="gridDataSourceShtiks" multiTemplateDataRows>
        <!-- arrow for inner grid start -->
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions"
            [style.display]="expandable_grid ? '' : 'none'">
            &nbsp;
          </th>
          <ng-container *matCellDef="let element">
            <td mat-cell style="width: 10px" *ngIf="checkforInnerGrid(element)">
              <button style="width: 15px" [disableRipple]="true" mat-icon-button aria-label="expand row" (click)="
                  expandedElement =
                    expandedElement === element ? null : element;
                  $event.stopPropagation()
                ">
                <mat-icon style="color: black" *ngIf="expandedElement === element">keyboard_arrow_down</mat-icon>
                <mat-icon style="color: black" *ngIf="expandedElement !== element">keyboard_arrow_right</mat-icon>
              </button>
            </td>
          </ng-container>
        </ng-container>

        <!-- arrow for inner grid end-->

        <ng-container *ngIf="
            isSelectableRow &&
            (isSelectableRow == true || isSelectableRow == 'true')
          " matColumnDef="select">
          <th class="width-100" style="width: 3%" mat-header-cell *matHeaderCellDef>
            <mat-checkbox class="gridSelectionChkbx gridSelectionChkbxHeader"
              (change)="$event ? masterToggle($event.checked) : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell class="gridSelectionChkbxCell" *matCellDef="let row">
            <mat-checkbox class="gridSelectionChkbx" (click)="$event.stopPropagation()"
              (change)="$event ? rowToogle($event, row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="row_no">
          <th mat-header-cell *matHeaderCellDef>L.No</th>
          <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container> -->

        <ng-container *ngFor="let column of columnsToDisplayShtiks; let i = index">
          <ng-container *ngIf="column.isVisible">
            <ng-container *ngIf="
                column.field == 'status' || column.field == 'is_active';
                else noStatusCol
              " matColumnDef="{{ column.field }}">
              <th mat-header-cell *matHeaderCellDef cdkDrag [ngClass]="column.align">
                <div class="">
                  <div>{{ column.displayName }}</div>
                  <!-- hiding this bc sorting was not working properly -->
                  <!-- <div
                    *ngIf="isallowedSort === 'true' || isallowedSort === true"
                    class="ms-2"
                    (click)="
                      isDivDisabled()
                        ? null
                        : emitSortDirection(asc ? 'asc' : 'desc', column.field)
                    "
                  >
                    <img
                      class="shtiks-sort"
                      src="../../../../assets/images/sorting-icon.png"
                    />
                  </div> -->
                </div>
              </th>
              <td class="status-cell" mat-cell *matCellDef="let element" [ngClass]="column.align">
                <button disabled [attr.status]="
                    element[column.field] == 1
                      ? 'Active'
                      : element[column.field] == 0
                      ? 'In-Active'
                      : element[column.field]
                  " mat-stroked-button>
                  {{
                  element[column.field] == "picked"
                  ? "Picked"
                  : element[column.field] == 1 ||
                  element[column.field] == true
                  ? "Active"
                  : element[column.field] == 0 ||
                  element[column.field] == false
                  ? "In-Active"
                  : element[column.field]
                  }}
                </button>
              </td>
            </ng-container>

            <ng-template #noStatusCol [ngSwitch]="column.Stiky">
              <ng-container *ngSwitchCase="'Start'" matColumnDef="{{ column.field }}">
                <th mat-header-cell *matHeaderCellDef [ngClass]="column.align">
                  <div class="d-flex align-items-center">
                    <div>{{ column.displayName }}</div>
                  </div>
                </th>
                <td style="overflow-wrap: anywhere" mat-cell *matCellDef="let element" [class]="column.align"
                  class="text-rap" [ngClass]="{
                    sorted: sort.active == column.field && sort.direction != '',
                    'text-secondary': column.clickable
                  }">
                  <div *ngIf="
                      column.displayName !== 'SKU' ||
                      column.displayName !== 'Kit Name'
                    ">
                    {{ element[column.field] }} yes
                  </div>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'End'" matColumnDef="{{ column.field }}">
                <th mat-header-cell *matHeaderCellDef [ngClass]="column.align">
                  <div class="d-flex align-items-center">
                    <div>{{ column.displayName }}</div>
                  </div>
                </th>
                <td style="overflow-wrap: anywhere" mat-cell *matCellDef="let element" [class]="column.align"
                  class="text-rap" [ngClass]="{
                    sorted: sort.active == column.field && sort.direction != '',
                    'text-secondary': column.clickable
                  }">
                  {{ element[column.field] }}
                </td>
              </ng-container>

              <ng-container *ngSwitchDefault matColumnDef="{{ column.field }}">
                <th mat-header-cell *matHeaderCellDef cdkDrag [ngClass]="column.align">
                  <div class="">
                    <div>{{ column.displayName }}</div>
                    <!-- hiding this bc sorting was not working properly -->
                    <!-- <div
                      *ngIf="isallowedSort === 'true' || isallowedSort === true"
                      class="ms-2"
                      (click)="
                        isDivDisabled()
                          ? null
                          : emitSortDirection(
                              asc ? 'asc' : 'desc',
                              column.field
                            )
                      "
                    >
                      <img
                        class="shtiks-sort"
                        src="../../../../assets/images/sorting-icon.png"
                      />
                    </div> -->
                  </div>
                </th>

                <td style="overflow-wrap: anywhere" [ngStyle]="
                    column?.columnWidth !== undefined
                      ? { width: column?.columnWidth }
                      : {}
                  " mat-cell *matCellDef="let element; let p = dataIndex" [class]="column.align" class="text-rap"
                  [ngClass]="{
                    sorted:
                      sort?.active == column.field && sort.direction != '',
                    'text-secondary': column.clickable
                  }">
                  <ng-container *ngIf="column?.progressBar">
                    <c-progress [height]="20">
                      <c-progress-bar [value]="
                          element[column.field] == 0
                            ? 200
                            : element[column.field]
                        " [color]="
                          element[column.field] > 0 &&
                          element[column.field] < 41
                            ? 'danger'
                            : element[column.field] > 40 &&
                              element[column.field] < 71
                            ? 'info'
                            : element[column.field] > 71 &&
                              element[column.field] < 100
                            ? 'warning'
                            : element[column.field] == 100
                            ? 'success'
                            : 'secondary'
                        ">{{ element[column.field] }}%</c-progress-bar>
                    </c-progress>
                  </ng-container>

                  <ng-container *ngIf="column?.field == 'line_no'">
                    <span>{{ p + 1 }}</span>
                  </ng-container>
                  <!-- <ng-container *ngIf="column?.displayName == 'Final Count Variance'">

                    <mat-form-field
                    *ngIf="column.isEditable"
                    appearance="legacy"
                    floatLabel="never"
                  >
                    <input
                      placeholder="0"
                      type="number"
                      matInput
                      min="1"
                      max="100000"
                      [(ngModel)]="element.final_count_variance"
                      onkeydown="if(event.key==='e' || event.key==='+' || event.key==='-' || event.key==='.'){event.preventDefault();}"
                      oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                    />
                  </mat-form-field>
                  <span *ngIf="!column.isEditable">{{
                    element[column.field] ? element[column.field] : "-"
                  }}</span>
                  </ng-container> -->
                  <ng-container *ngIf="column?.displayName == 'Final Count Variance'">
                    <mat-form-field *ngIf="column.isEditable" appearance="legacy" floatLabel="never">
                      <input placeholder="0" type="number" matInput [min]="-100000" max="100000"
                        [(ngModel)]="element.final_count_variances" (change)="QtyFinalCount(element)"
                        onkeydown="if(event.key==='e' || event.key==='+' || event.key==='.'){event.preventDefault();}" />
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="column?.clickable_action">
                    <a href="javascript:void(0)" *ngIf="column.clickable" (click)="
                        acionClick(
                          column.clickableItem
                            ? column.clickableItem
                            : 'detail',
                          element
                        );
                        $event.stopPropagation()
                      " [ngClass]="
                        element.pr_no === null
                          ? 'text-decoration-none cursor-pointer'
                          : 'cursor-pointer'
                      " class="pr" rel="noopener noreferrer">
                      {{ element[column.field] ? element[column.field] : "-" }}
                    </a>
                  </ng-container>

                  <ng-container *ngIf="
                      column.displayName !== 'SKU' &&
                      column.displayName !== 'Kit Name' &&
                      column.displayName !== 'Component Name' &&
                      !column?.progressBar &&
                      column.field !== 'line_no' &&
                      !column?.clickable_action
                    ">
                    <span *ngIf="column.displayName == 'Location'">
                      <!-- <span>{{element[column.field]}}</span> -->
                    </span>

                    <span *ngIf="column.displayName == 'Available'">
                      <!-- <span>{{element[column.field]}}</span> -->
                    </span>

                    <span *ngIf="
                        column.displayName == 'SKU Qty' ||
                        column.displayName == 'Qty'
                      ">
                      <mat-form-field *ngIf="column.isEditable" appearance="legacy" floatLabel="never">
                        <input placeholder="0" type="number" matInput min="1" [(ngModel)]="element.quantity"
                          (change)="maxLimitForQtyGeneral(element)"
                          onkeydown="if(event.key==='e' || event.key==='+' || event.key==='-' || event.key==='.'){event.preventDefault();}"
                          oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');" />
                      </mat-form-field>
                      <span *ngIf="!column.isEditable">{{
                        element[column.field] ? element[column.field] : "-"
                        }}</span>
                    </span>

                    <span *ngIf="column.displayName == 'Created At'">
                      <span>{{
                        element[column.field]
                        ? (element[column.field])
                        : "-"
                        }}</span>
                    </span>

                    <!-- Concat fields start -->
                    <div *ngIf="column.isConcat">
                      <div class="d-flex flex-column"
                        *ngIf="element[column.field] || element[column.concatField]; else empty"
                        matTooltip="{{ element[column.field] ? element[column.field] + '&#10;' : '' }}{{ element[column.concatField] || ''}}"
                        [matTooltipPosition]="'left'">
                        <div class="text-truncate">
                          {{element[column.field] || '-'}}
                        </div>
                        <div class="text-truncate">
                          {{element[column.concatField] || '-'}}
                        </div>
                      </div>
                      <ng-template #empty>
                        {{'-'}}
                      </ng-template>
                    </div>
                    <!-- Concat fields end -->

                    <span *ngIf="column.displayName == 'Created Date/Time'">
                      <span>
                        {{
                        element[column.field]
                        ? (element[column.field] | date : 'M/d/yy h:mm a')
                        : "-"
                        }}
                      </span>
                    </span>

                    <span *ngIf="
                        column.displayName == 'Date Created' &&
                        element[column.field]
                      ">
                      <span>{{
                        element[column.field] | date : "MM/dd/yyyy"
                        }}</span>
                    </span>

                    <span *ngIf="column.displayName == 'Future'">
                      <button *ngIf="!element.isIncoming" mat-icon-button style="text-align: center" (click)="
                          getInventoryCount(element, element.id, 'incoming');
                          $event.stopPropagation()
                        ">
                        <mat-icon style="color: black">sync</mat-icon>
                      </button>

                      <span *ngIf="element.isIncoming">{{
                        element.incoming_qty ? element.incoming_qty : "0"
                        }}</span>
                      <!-- <div *ngIf="toShow" back-icon (click)="detail()" id="bk" class="cursor-pointer"><i class="icon-arrow-back"></i></div> -->
                    </span>

                    <span *ngIf="column.displayName == 'Incoming'">
                      <button *ngIf="!element.isIncoming" mat-icon-button style="text-align: center" (click)="
                          getInventoryCount(element, element.id, 'incoming');
                          $event.stopPropagation()
                        ">
                        <mat-icon style="color: black">sync</mat-icon>
                      </button>

                      <span *ngIf="element.isIncoming">{{
                        element.incoming_qty ? element.incoming_qty : "0"
                        }}</span>
                      <!-- <div *ngIf="toShow" back-icon (click)="detail()" id="bk" class="cursor-pointer"><i class="icon-arrow-back"></i></div> -->
                    </span>

                    <span *ngIf="column.displayName == 'Intransit'">
                      <button *ngIf="!element.isIntransit" mat-icon-button style="text-align: center" (click)="
                          getInventoryCount(element, element.id, 'intransit');
                          $event.stopPropagation()
                        ">
                        <mat-icon style="color: black">sync</mat-icon>
                      </button>
                      <span *ngIf="element.isIntransit">{{
                        element.intransit_qty ? element.intransit_qty : "0"
                        }}</span>
                      <!-- <div *ngIf="toShow" back-icon (click)="detail()" id="bk" class="cursor-pointer"><i class="icon-arrow-back"></i></div> -->
                    </span>

                    <span *ngIf="column.displayName == 'Reserve'">
                      <button *ngIf="!element.isReserve" mat-icon-button style="text-align: center" (click)="
                          getInventoryCount(element, element.id, 'reserve');
                          $event.stopPropagation()
                        ">
                        <mat-icon style="color: black">sync</mat-icon>
                      </button>
                      <span *ngIf="element.isReserve">{{
                        element.reserve_qty ? element.reserve_qty : "0"
                        }}</span>
                      <!-- <div *ngIf="toShow" back-icon (click)="detail()" id="bk" class="cursor-pointer"><i class="icon-arrow-back"></i></div> -->
                    </span>

                    <span *ngIf="
                        column.displayName == 'UOM' ||
                        column.displayName == 'Base Unit'
                      ">
                      <div class="custom-select poLinesSelectBox" *ngIf="column.isVisible && column.type == 'dropdown'"
                        style="margin-bottom: 1px">
                        <span>{{
                          element.base_unit ? element.base_unit : "-"
                          }}</span>

                        <!-- <ng-select [(ngModel)]="element.uom_id" [appendTo]="'body'" [clearable]="false"
                          [searchable]="false" placeholder="Select" class="line-select">
                          <ng-option *ngFor="let item of get_uom_list(element)" [value]="element.base_unit">{{ item.name
                            }}</ng-option>
                           <ng-option *ngFor="let item of uom_list" [value]="item.id">{{ item.name }}</ng-option> -->
                        <!-- </ng-select> -->
                      </div>

                      <div *ngIf="
                          column.isVisible && !column.isTrackingEnabled &&
                          (column.type == undefined || column.type == '')
                        ">
                        <a [routerLink]="[activeURL, element['id']]" *ngIf="column.clickable" (click)="
                            acionClick(
                              column.clickableItem
                                ? column.clickableItem
                                : 'detail',
                              element
                            );
                            $event.stopPropagation()
                          " [ngClass]="
                            element.pr_no === null
                              ? 'text-decoration-none cursor-pointer'
                              : 'cursor-pointer'
                          " class="pr" rel="noopener noreferrer">
                          {{
                          element[column.field] ? element[column.field] : "-"
                          }}
                        </a>
                        <span *ngIf="!column.clickable">{{
                          element[column.field]
                          ? element[column.field]
                          : element[column.field] == "0"
                          ? "0"
                          : "-"
                          }}</span>

                      </div>
                    </span>

                    <span *ngIf="
                        column.displayName !== 'SKU Qty' &&
                        column.displayName !== 'Base Unit' &&
                        column.displayName !== 'Qty' &&
                        column.displayName !== 'UOM' &&
                        column.displayName !== 'Created At' &&
                        column.displayName !== 'Date Created' &&
                        column.displayName !== 'Created Date/Time' &&
                        column.displayName !== 'Future' &&
                        column.displayName !== 'Incoming' &&
                        column.displayName !== 'Intransit' &&
                        column.displayName !== 'Reserve'
                      ">
                      <a [routerLink]="[activeURL, element['id']]" *ngIf="column.clickable" (click)="
                          acionClick(
                            column.clickableItem
                              ? column.clickableItem
                              : 'detail',
                            element
                          );
                          $event.stopPropagation()
                        " [ngClass]="
                          element.pr_no === null
                            ? 'text-decoration-none cursor-pointer'
                            : 'cursor-pointer'
                        " class="pr" rel="noopener noreferrer">
                        {{
                        element[column.field] ? element[column.field] : "-"
                        }}
                      </a>
                      <span *ngIf="column.boolField">{{
                        element[column.field] ? "Yes" : "No"
                        }}</span>
                      <span
                        *ngIf="!column.clickable && !column.boolField && !column.isConcat && !column.isTrouble && !column.isTrackingEnabled">{{
                        element[column.field]
                        ? element[column.field]
                        : element[column.field] == "0"
                        ? "0"
                        : "-"
                        }}
                      </span>
                    </span>

                    <span *ngIf="column?.isTrackingEnabled"
                      [ngClass]=" element[column.field]?.length>0 ? 'pr cursor-pointer' : ''"
                      (click)="trackingClick(checkFieldType(element[column.field]) == 'array' ? element[column.field] : element)">
                      <ng-container *ngIf="checkFieldType(element[column.field]) == 'array' else typestring">
                        {{
                        element[column.field].length>0
                        ? element[column.field].length
                        : "-"
                        }}
                      </ng-container>
                      <ng-template #typestring>
                        {{
                        element[column.field]
                        ? element[column.field]
                        : element[column.field] == "0"
                        ? "0"
                        : "-"
                        }}
                      </ng-template>
                    </span>
                  </ng-container>

                  <div joyrideStep="step1" [nextTemplate]="nextButton" [stepContent]="customContent" *ngIf="
                      column.displayName == 'SKU' ||
                      column.displayName == 'Kit Name' ||
                      column.displayName == 'Component Name'
                    ">
                    <div class="d-flex align-items-center" (click)="
                        acionClick(
                          column.clickableItem
                            ? column.clickableItem
                            : 'detail',
                          element
                        )
                      ">
                      <img [src]="
                          element[column.fieldprd_img]
                            ? element[column.fieldprd_img]
                            : '../../../../assets/images/default-image.png'
                        " width="33" height="33" alt="" class="pro_img cursor-pointer"
                        (error)="handleImgError($event)" />
                      <div class="d-block pl-5 cursor-pointer" style="width: 100%" matTooltipPosition="below"
                        [matTooltipShowDelay]="500" [matTooltip]="
                          'SKU: ' +
                          element[column.fieldprd_sku] +
                          '\n' +
                          element[column.field]
                        " (click)="
                          acionClick(
                            column.clickableItem
                              ? column.clickableItem
                              : 'detail',
                            element
                          )
                        ">
                        <p cTextColor="secondary" class="mb-1 sku cursor-pointer" matTooltipHideDelay="1000">
                          {{ element[column.fieldprd_sku] }}
                        </p>
                        <a *ngIf="ModuleName == 'inventory'" class="text-rap mb-0 cursor-pointer" [ngClass]="
                            column?.isHiglighed == false ? 'pr pr-b' : 'pr'
                          " matTooltipHideDelay="1000">
                          {{
                          element[column.field] ? element[column.field] : "-"
                          }}
                        </a>
                        <a *ngIf="
                            ModuleName != 'inventory' &&
                            ModuleName != 'productNkit'
                          " [routerLink]="
                            column.clickable ? [activeURL, element['id']] : []
                          " class="text-rap mb-0 cursor-pointer" [ngClass]="
                            column?.isHiglighed == false ? 'pr pr-b' : 'pr'
                          " matTooltipHideDelay="1000">
                          {{
                          element[column.field] ? element[column.field] : "-"
                          }}
                        </a>
                        <a *ngIf="ModuleName == 'productNkit'" [routerLink]="
                            column.clickable ? [activeURL, element['id']] : []
                          " [queryParams]="{ is_kit: element?.is_kit }" class="text-rap mb-0 cursor-pointer" [ngClass]="
                            column?.isHiglighed == false ? 'pr pr-b' : 'pr'
                          " matTooltipHideDelay="1000">
                          {{
                          element[column.field] ? element[column.field] : "-"
                          }}
                        </a>
                        <!-- {{element.is_kit}} -->
                      </div>
                    </div>
                  </div>

                  <div *ngIf="column.isTrouble">
                <th mat-header-cell *matHeaderCellDef cdkDrag [ngClass]="column.align">
                  {{ column.displayName }}
                </th>
                <span>
                  <button mat-icon-button [disabled]="!element[column.field]" style="text-align:center"
                    (click)="openPopup()">
                    <mat-icon [ngClass]="{'disableIcon': !element[column.field]}"
                      style="color:red">error_outline</mat-icon>
                  </button>
                </span>
    </div>

    <ng-template #nextButton>
      <button class="tour-next-button" #joyrideNextButton>
        Next
      </button>
    </ng-template>
    <ng-template #customContent>
      <p>Select the SKU you want to adjust.</p>
    </ng-template>
    </td>
    </ng-container>
    </ng-template>
    </ng-container>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <!-- [style.overflow]="isOverflowHidden ? 'hidden' : 'auto'" -->
      <ng-container *matCellDef="let element">
        <td mat-cell *ngIf="checkforInnerGrid(element)" [attr.colspan]="initColumns.length">
          <div class="example-element-detail" [@detailExpand]="
                  element == expandedElement ? 'expanded' : 'collapsed'
                ">
            <table class="table-hover w-100" mat-table [dataSource]="innerGridData">
              <ng-container *ngFor="let column of innerGridColums; let i = index">
                <ng-container matColumnDef="{{ column?.field }}">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ column?.displayName }}
                  </th>
                  <td mat-cell *matCellDef="let element" [ngStyle]="
                          column?.columnWidth !== undefined
                            ? { width: column?.columnWidth }
                            : {}
                        ">
                    <div>
                      {{
                      element[column.field] ? element[column.field] : "-"
                      }}
                    </div>
                  </td>
                </ng-container>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="innerGridColumsName"></tr>
              <tr mat-row *matRowDef="let row; columns: innerGridColumsName"></tr>
            </table>
            <!-- <div>
                  <tr *ngFor="let row of innerGridData" class="innerGrid">
                    <td
                      *ngFor="
                        let column of columnsToDisplayShtiks;
                        let isFirst = first;
                        let isLast = last
                      "
                    >
                      <ng-container *ngIf="isFirst; else notFirst">
                        <a
                          (click)="innerGridClick(row)"
                          [ngStyle]="{ width: column.columnWidth }"
                        >
                          {{ row[column.field]?.toString() || "-" }}</a
                        >
                      </ng-container>

                      <ng-template #notFirst>
                        <ng-container *ngIf="isLast; else notLast">
                          <button
                            disabled
                            mat-stroked-button
                            [ngStyle]="{ width: column.columnWidth }"
                          >
                            {{ row[column.field]?.toString() || "-" }}
                          </button>
                        </ng-container>


                        <ng-template
                          #notLast
                          style="overflow-wrap: anywhere"
                          [class]="column.align"
                          class="text-rap"
                          [ngStyle]="{ width: column.columnWidth }"
                        >
                          {{ row[column.field]?.toString() || "-" }}
                        </ng-template>
                      </ng-template>
                    </td>
                  </tr>
                </div> -->
          </div>
        </td>
      </ng-container>
    </ng-container>

    <!-- Pivot Column -->
    <ng-container matColumnDef="pivot">
      <th mat-header-cell class="text-center" *matHeaderCellDef>
        <img *ngIf="hasMoreHeader" id="btn-clmfilter" src="../../../../assets/images/plus icon purple.svg"
          (click)="gridColumnFilter()" alt="" />
      </th>
      <td mat-cell align="center" *matCellDef="let element; let q = dataIndex">
        <button mat-icon-button *ngIf="isActionColVisible" [disabled]="
                isallowedAction === 'false' ||
                isallowedAction === false ||
                element?.disabled
              " [matMenuTriggerFor]="matMenu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #matMenu="matMenu">
          <button *ngIf="element.toCheckOut" mat-menu-item (click)="acionClick('checkout', element)">
            <span>Print BOL & Check Out</span>
          </button>
          <button *ngIf="element.toAccess" mat-menu-item (click)="acionClick('access', element)">
            <span>Access Account</span>
          </button>
          <button *ngIf="element.clone" mat-menu-item (click)="acionClick('clone', element)">
            <span>Clone</span>
          </button>
          <button *ngIf="element.toInactive" mat-menu-item (click)="acionClick('inactive', element)">
            <span>Inactive</span>
          </button>
          <button *ngIf="element.toresetPassword" mat-menu-item (click)="acionClick('resetPassword', element)">
            <span>Reset Password</span>
          </button>
          <button *ngIf="element.toactive" mat-menu-item (click)="acionClick('active', element)">
            <span>Active</span>
          </button>
          <button *ngIf="element.toCheckIn" mat-menu-item (click)="acionClick('checkIn', element)">
            <span>Check In</span>
          </button>
          <button *ngIf="element.toCreateTask" mat-menu-item (click)="acionClick('createTask', element)">
            <span>Create Job</span>
          </button>
          <button *ngIf="element.toAddAsn" mat-menu-item (click)="acionClick('addAsn', element)">
            <span>Add ASN</span>
          </button>
          <button *ngIf="element.toEdit" mat-menu-item (click)="acionClick('edit', element)">
            <span>Edit</span>
          </button>
          <button *ngIf="element.toPrintASN" mat-menu-item (click)="acionClick('printASN', element)">
            <span>Print ASN</span>
          </button>

          <button *ngIf="element.toPrint" mat-menu-item (click)="acionClick('print', element)">
            <span style="text-transform: math-auto;">Print Label(s)</span>
          </button>

          <button *ngIf="element.toDelete" mat-menu-item (click)="deleteWarning(element)">
            <span>Delete</span>
          </button>
          <button *ngIf="element.toAssign" mat-menu-item (click)="acionClick('assign', element)">
            <span>Assign</span>
          </button>
          <button *ngIf="element.toAssignOrder" mat-menu-item (click)="acionClick('assignOrder', element)">
            <span>Assign Order</span>
          </button>
          <button *ngIf="element.toReAssign" mat-menu-item (click)="acionClick('assign', element)">
            <span>Re-Assign</span>
          </button>
          <button *ngIf="element.toExecute" mat-menu-item (click)="acionClick('execute', element)">
            <span>Execute</span>
          </button>
          <button *ngIf="element.toCancel" mat-menu-item (click)="acionClick('cancel', element)">
            <span>Cancel</span>
          </button>
          <button *ngIf="element.toCreateFulfillment" mat-menu-item (click)="acionClick('createFulfillment', element)">
            <span>Create Fulfillment</span>
          </button>
          <!-- <button *ngIf="element.toDelete" mat-menu-item (click)="
                  acionClick('delete', element); $event.stopPropagation()
                ">
                <span>Delete</span>
              </button> -->
          <!-- <button mat-menu-item (click)="
                  acionClick('comment', element); $event.stopPropagation()
                ">
                <span>Comment</span>
              </button> -->
          <!-- <button *ngIf="showWSbuttons" mat-menu-item (click)="
                  acionClick('createArea', element); $event.stopPropagation()
                ">
                <span>Create Area</span>
              </button>
              <button *ngIf="showAreabuttons" mat-menu-item (click)="
                  acionClick('createLocation', element); $event.stopPropagation()
                ">
                <span>Create Location</span>
              </button> -->
        </mat-menu>
      </td>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="investigate">
      <th mat-header-cell *matHeaderCellDef style="width: 3%"></th>
      <td mat-cell *matCellDef="let element; let i = index">
        <img *ngIf="
                element.isInvestigate && !isfirstInvestigationDone && !completed
              " class="mr-3 cursor-pointer" id="btn-clmfilter" src="../../../../assets/images/resume.png" alt=""
          (click)="acionInvestigate('investigate', element)" />
        <div *ngIf="isfirstInvestigationDone && !completed" class="d-flex justify-content-between">
          <img class="mr-3 cursor-pointer" id="btn-clmfilter" src="../../../../assets/images/EDIT.png" alt=""
            (click)="acionInvestigate('investigate', element)" />
          <img class="mr-3" id="btn-clmfilter" src="../../../../assets/images/Tick.png" alt="" />
        </div>
        <img *ngIf="completed" class="mr-3" id="btn-clmfilter" src="../../../../assets/images/fill-green-tick.png"
          alt="" />
      </td>
    </ng-container>
    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef>Priority</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="priority-tasks cursor-pointer" (click)="acionPriority('priority', element)">
          <span class="text-white">1</span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="initColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: initColumns; let i = dataIndex" [class.even-rows]="i % 2 == 0"
      [class.example-expanded-row]="expandedElement === element" class="example-element-row"></tr>
    <tr mat-row *matRowDef="let element; let row; columns: ['expandedDetail']" class="example-detail-row"
      [class.example-detail-expanded-row]="expandedElement === element"></tr>
    </table>
  </div>
  <div class="no-records-div">
    <p cTextColor="secondary" *ngIf="paginationDataSourceShtiks.total === 0">
      No records found!
    </p>
  </div>
</div>

<div class="d-flex justify-content-between align-items-center resp-classes" [ngClass]="
      customCssClass
        ? ''
        : service.profileDetail?.account_type === 'service_provider'
        ? 'sp-list-footer'
        : 'client-list-footer'
    ">
  <!-- Col to select no. of entries -->
  <div class="select-entries" *ngIf="paginationDataSourceShtiks.total">
    <div class="d-flex align-items-center gap-2">
      <span>No. of entries</span>
      <ng-select [(ngModel)]="selectedNoOfEntries" [clearable]="false" [searchable]="false"
        (change)="onSelectEntry($event)">
        <ng-option *ngFor="let no of noOfEntries" [value]="no">{{
          no
          }}</ng-option>
      </ng-select>
    </div>
    <span>
      Showing {{ paginationDataSourceShtiks.from }}-{{
      paginationDataSourceShtiks.to
      }}
      from {{ paginationDataSourceShtiks.total }} data
    </span>
  </div>
  <!-- When there will be no records. -->
  <!-- <p cTextColor="secondary" *ngIf="paginationDataSourceShtiks.total === 0">
      No records found!
    </p> -->
  <div class="resp-pagination">
    <ul class="list" *ngIf="paginationDataSourceShtiks.total">
      <li>
        <el-pagination [model]="paginationDataSourceShtiks?.current_page" (modelChange)="pagechange($event)"
          [total]="paginationDataSourceShtiks?.total" [page-count]="paginationDataSourceShtiks?.last_page"
          [page-size]="paginationDataSourceShtiks?.per_page" [layout]="['prev', 'pager', 'next']">
        </el-pagination>
      </li>
    </ul>
  </div>
</div>
</div>
